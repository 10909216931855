#queue {
    .queue-header {
        background: #888;
        color: $white;
        padding: 6px 10px;
        margin-bottom: 10px;
    }

    #job-buttons {
        margin-bottom: 20px;
    }

    #joblist-container {
        height: 400px;
        /*overflow-y: scroll;*/
    }

    #userlist-container {
        height: 400px;
        /*overflow-y: scroll;*/
    }
}