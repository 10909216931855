.job-panel {
    @extend .panel;
    z-index: 10; // so that job is in front of user-panel in queue view
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    cursor: pointer;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #ddd;

    &:hover {
        border-color: #aaa;
    }

    .panel-body {
        padding: 5px;
    }

    .triggerMaker {
        font-size: 30px;
        margin-top: -16px;
        text-align: center;
        color: #f75448;
        display: block;
        width: 30px;

        &.disabled {
            color: #777;
            cursor: not-allowed;
        }
    }
}