.capitalize {
    text-transform: capitalize;
}

.p-t-5 {
    padding-top:5px;
}

.overlay {
    display: none; // when used display as flex: $('.overlay').show().css('display', 'flex');
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99998;
    background: rgba(250, 250, 250, 0.6);
    align-items: center;
    justify-content: center;

    i {
        font-size:24px;
    }
}

/* 
        show a notification after ajax requests
        usage:
        $("#ajax-status").text(statusMessage).slideDown().addClass("bg-" + statusType);
        $("#ajax-status-message").addClass("text-" + statusType);
*/
#ajax-status {
    display: none; // usage: see above
    position: fixed;
    top: 0px;
    z-index: 99999;
    padding: 7px 10px;
    width: 100%;
    text-align: center;
}
.modal {
    z-index: 99997
}