div.datepicker {
    th, td {
        display: table-cell !important; 
        padding: 5px 5px !important;
    }

    input {
        width: 0px;
        height: 0px;
        padding: 0px;
        border: 0px;
        display: inline;
    }
}