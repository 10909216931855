.btn-primary
{
    color: $white;
    background-color: $appix-blue;
    border-color: $appix-blue;
}
.btn-primary:hover
{
    color: $white;
    background-color: $appix-blue-lighter;
    border-color: $appix-blue;
}

.btn-secondary
{
    color: $white;
    background-color: $appix-green;
    border-color: $appix-green;
}

.btn-secondary:hover
{
    color: $white;
    background-color: $appix-green-lighter;
    border-color: $appix-green;
}

.btn.btn-default {
    color: $white;
    background-color: $appix-green;
    border-color: $appix-green;
}

.btn.btn-default:hover {
    color: $white;
    background-color: $appix-green-lighter;
    border-color: $appix-green;
}

.btn.btn-danger {
    color: $white;
    background-color: $appix-red;
    border-color: $appix-red;
}

.btn.btn-danger:hover {
    color: $white;
    background-color: $appix-red-lighter;
    border-color: $appix-red;
}

.btn.btn-primary
{
    color: $white;
    background-color: $appix-blue;
    border-color: $appix-blue;
}

.btn.btn-primary:hover
{
    color: $white;
    background-color: $appix-blue-lighter;
    border-color: $appix-blue;
}
