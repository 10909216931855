.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
    border:1px solid #ccc;
    border-radius:6px;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 10px;
}

.form-signin .checkbox {
    font-weight: normal;
}

.form-signin .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin input {
    margin-bottom: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#form-forgot,#form-newuser {
    visibility:hidden;
}

.login .container {
    text-align:center;	
}

.login-form-wrapper {
    display: block;
    margin: auto;
    width: 300px;
    max-width:100%;
}

.form-header {
    @extend .clearfix;
    background: $darkgray;
    color: $white;
    padding: 10px 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;

    h2 {
        @extend .pull-left;
        font-size: 23px;
        line-height: 28px;
        margin: 0;
    }
}

form.appix-form {
    background: $white;
    padding: 15px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    //@extend .form-horizontal;

    h1, h2, h3 {
        clear: both;
    }

    label {
        @extend .col-sm-3, .text-right;
        padding-top: 0;
        padding-left: 0; // for mobile view
        margin-bottom: 0;
        @media (min-width: 768px) {
            padding-top: 7px;
            padding-left: 15px;
        }
    }

    select {
        @extend .form-control, .col-sm-8;
        margin-bottom: 15px;
        @media (min-width: 768px) {
            width: 66.6666666667%;
        }
    }

    input[type=checkbox] {
        box-shadow: none !important;
        margin: 0;
    }

    input:not([type=button]):not([type=submit]), textarea {
        @extend .form-control, .col-sm-8;
        margin-bottom: 15px;
        @media (min-width: 768px) {
            width: 66.6666666667%;
            margin-bottom: 33px;
        }
        @media (min-width: 992px) {
            margin-bottom: 15px;
        }
    }

    input[type=button], input[type=submit] {
        // style buttons here
        @extend .btn, .btn-primary, .form-control, .col-sm-4, .col-sm-offset-7;
        @media (min-width: 768px) {
            min-width: 200px;
            width: auto;
        }
    }

    .form-group {
        @extend .row;
        margin: 0;
    }
}

form.appix-form-readonly {
    @extend form.appix-form;

    input:not([type=button]):not([type=submit]), select, textarea {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;   
        color: #8C8C8C;
        pointer-events: none;
    }

    input[type=checkbox] {
        opacity: 0.5;
    }

    .appix-form-enable-edit {
        -webkit-user-select: auto;
        -moz-user-select: auto;
        -ms-user-select: auto;
        -o-user-select: auto;
        user-select: auto;   
        color: #555555 !important;
        pointer-events: auto !important;
    }
}

div.appix-form {
    @extend form.appix-form;
    padding: 0;
}

div.appix-form-readonly {
    @extend form.appix-form;

    input:not([type=button]):not([type=submit]), select, textarea {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;   
        color: #8C8C8C;
        pointer-events: none;
    }

    .appix-form-enable-edit {
        input:not([type=button]):not([type=submit]), select, textarea {
            -webkit-user-select: auto;
            -moz-user-select: auto;
            -ms-user-select: auto;
            -o-user-select: auto;
            user-select: auto;   
            color: #555555 !important;
            pointer-events: auto !important;
        }
    }
}

div.appix-form-readonly-full-width {
    input:not([type=button]):not([type=submit]), select, textarea {
        color: #8C8C8C;
        pointer-events: none;
    }
}

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}