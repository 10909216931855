// different backgrounds
.white-bg {
    background: $white;
}
.gray-bg {
    background: #888;
    color: white;
}
.darkgray-bg {
    background: $darkgray;
}
.blue-bg {
    background: $blue;
}
.lightblue-bg {
    background: $lightblue;
    &.border {
        border: 1px solid $blue;
    }
}

.bg-primary {
    background-color: $appix-blue;
}
