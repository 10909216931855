.calendar {
    overflow-y: scroll;
    background-color: #eee;

    .calendar-header {
        .link {
            cursor: pointer;
            padding: 8px;

            &:hover {
                background: $gray;
            }
        }
        .day {
            display: inline-block;

            &.active {
                background: $lightblue;
            }
        }
    }

    #joblist-scheduled {		
        .timeslot-line {
            border-top: 2px solid $white;
            border-bottom: 1px solid $white;
            margin-top: 9px;
        }
    }
}