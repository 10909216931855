// colors
$white: #ffffff;
$black: #000000;

$lightgray: #f0f3f5;
$gray: #e2e7eb;
$darkgray: #242a30;

$lightblue: #d9edf7;
$blue: #337ab7;

$appix-blue: #0c5Fa5;
$appix-blue-lighter: #3073AB;
$appix-blue-darker: #084A80;

$appix-green: #70bc52;
$appix-green-lighter: #99DB81;
$appix-green-darker: #4E9F30;

$appix-red: #FF0122;
$appix-red-lighter: #FF324E;
$appix-red-darker: #FF0023;
