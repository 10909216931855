.list-filter {
    font-size:14px;
}

.list-filter .filter-dropdown {
    display:inline;
}

.list-filter .btn.btn-default {
    border:none;
    box-shadow:none;
    background:transparent;
    border-radius:0;
    text-decoration:underline;
    font-weight:bold;
    border:none;
    padding:0 4px 4px;
    background:transparent;
    color:$darkgray;
}

.list-filter button {
    background:transparent;
}

.list-filter.well {
    padding:10px;
    background:$white;
}

#search_by {
    margin-top:15px;
}

#search_by.placeholder {
    text-align: center;
}

#search_by::-webkit-input-placeholder {
    text-align: center;
}

#search_by:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

#search_by::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

#search_by:-ms-input-placeholder {  
    text-align: center; 
}

#joblist-container .list-filter {
    margin:15px;
}