/* bootstrap overwrides */
ul.dropdown-menu {
    color: black;
    padding: 15px 20px;

    li.dropdown-header {
        padding-left: 0px;
    }
}


// Body
$body-bg: $lightgray;


// row was overwritten by tableview, set it back
.row > [class*="col-"] {
    padding: 0 15px;
}