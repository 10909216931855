#nav-wrapper {
    position: fixed;
    width: 250px;
    height: 100%;
    z-index: 9999;
    background-color: #222;
    overflow: hidden;
}

.navbar-logo {
    height: 53px;
    margin-top: 15px;
}

#navbar {
    a {
        color: #fff; 
        font-size: 16px;
    }

    li.active a, a:hover {
        color: #337ab7;
    }

    i {
        width: 30px;
    }
}

#left-main-nav {
    border-radius: 0;
    height: 100%;
    overflow-y: scroll; 
    //overflow-x: hidden;
    padding: 0 0 0 7px; 
    width: 268px;
}

#navbar>.nav-small li a {
    font-size:12px;
}

.nav-stacked>li.active {
    background:#eee;
}

#legal {
    padding:50px 30px 5px;
}

#legal a {
    color:$white;
    font-size:9px;
}

.recent-job {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}