.user-panel {
    @extend .panel;
    padding: 30px 20px;

    .triggerMaker {
        font-size: 30px;
        margin-top: -16px;
        text-align: center;
        color: #f75448;
        display: block;

        &.disabled {
            color: #777;
            cursor: not-allowed;
        }
    }
}