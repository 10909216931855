/*data-table Adjustments */
.box4table{ 
    display:block; 
    clear:both;
}

.dataTables_wrapper{ 
    display: block; 
    clear: both; 
    padding: 20px; 
    background: #fff;
}

.dataTables_filter{ 
    text-align: right;
}

.box4table .panel-heading h4 {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    line-height: normal; 
    width: 75%; 
    padding-top: 8px;
}

.box4table .panel-heading { 
    min-height: 50px;
}

/* hide default arrows */
table.dataTable thead .sorting:before, 
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    display: none !important;
}

table.dataTable.no-footer {
    border-bottom: 1px solid #ddd !important;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled
{
    vertical-align: top;
    background-repeat: no-repeat;
    padding-right: 20px;
    background-position: right 10px !important;
}

table.dataTable td, th {
    word-wrap: break-word;
}

table.dataTable thead .sorting{
    background-image:url("../images/sort_both.png")
}

table.dataTable thead .sorting_asc{
    background-image:url("../images/sort_asc.png")
}

table.dataTable thead .sorting_desc{
    background-image:url("../images/sort_desc.png")
}

table.dataTable thead .sorting_asc_disabled{
    background-image:url("../images/sort_asc_disabled.png")
}

table.dataTable thead .sorting_desc_disabled{
    background-image:url("../images/sort_desc_disabled.png")
}

.output {
    margin-bottom: 0;
    color: #999;
}
.ellipsis {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}
/*END data-table Adjustments */

// necessary for alignment of dropdown
.box4table .panel-heading {
    position: relative;
}

